/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import moment from 'moment-mini';
import {
  truncateString, getUtilityType, isMobile,
} from '../../managers/Helpers';
import AccountManager from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import SimpleModal from '../modal/modal';
import Throbber from '../throbber';
import AzureMediaPlayer from '../video/azureMediaPlayer';
import Gallery from '../account/gallery';
import ContestEntry from '../utility/contestEntry';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CodeRevealer from './codeRevealer';
import PollsEntry from '../utility/pollsEntry';

function UtilityList({
  slideshow, layout, noHeader, nftId, assetUtility, collectionUtility, onPlay, onPreviewCTA, isPreviewMode,
}) {
  const [formattedUtilities, setFormattedUtilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [showFileViewer, setFileViewerState] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showContestModal, setShowContestModal] = useState(false);
  const [contestId, setContestId] = useState(null);
  const [showPollsModal, setShowPollsModal] = useState(false);
  const [currentPoll, setCurrentPoll] = useState(null);
  const [submittedPoll, setSubmittedPoll] = useState(false);

  const currentDate = moment().unix() * 1000;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const generateCommerceUrl = async (gatedCommerceId, e) => {
    e.preventDefault();
    setShowModal(true);
    const generatedCommerceUrl = await CreatorManager.generateGatedCommerceUrl(gatedCommerceId, nftId, AccountManager.getToken());
    if (generatedCommerceUrl && generatedCommerceUrl.success) {
      window.open(generatedCommerceUrl.draftOrderUrl, '_blank');
      setShowModal(false);
    } else {
      toast.error(generatedCommerceUrl.message || 'Oops. Something went wring generating your link. Please try again later.');
      setShowModal(false);
    }
  };

  const openPollModal = (utility, e) => {
    e.preventDefault();
    const pollId = utility.utilityId;
    utility.polls.forEach((poll) => {
      if (pollId === poll.utilityId) {
        setCurrentPoll(poll);
      }
    });
    setShowPollsModal(true);
  };

  const formatUtilities = (assetUtility, collectionUtility) => {
    setFormattedUtilities(assetUtility.concat(collectionUtility));
  };

  useEffect(() => {
    formatUtilities(assetUtility, collectionUtility);
  }, [assetUtility, collectionUtility]);

  const closeFileViewer = (e) => {
    if (e) {
      e.preventDefault();
    }
    setFileViewerState(false);
    setSelectedVideo(null);
    setSelectedFile(null);
  };

  const loadAsset = async (assetUrl, assetType) => {
    setFileViewerState(true);
    if (assetType === 'image') {
      setSelectedFile(assetUrl);
    } else if (assetType === 'pdf') {
      setSelectedPdf(assetUrl);
    } else {
      setSelectedVideo(assetUrl);
    }
  };

  const getGatedAsset = async (gatedContent, e) => {
    e.preventDefault();
    if (isPreviewMode) {
      onPreviewCTA();
      return;
    }
    setShowModal(true);
    setTimeout(async () => {
      const gatedContentFile = await AccountManager.getGatedContentV2(AccountManager.getToken(), gatedContent.gatedContentId);
      setShowModal(false);
      if (gatedContentFile && gatedContentFile.success) {
        if (gatedContent.contentType === 1) {
          loadAsset(gatedContentFile.fileUrl, 'image');
        } else if (gatedContent.contentType === 4) {
          loadAsset(gatedContentFile.fileUrl, 'pdf');
        } else {
          loadAsset(gatedContentFile.fileUrl, 'video');
        }
      } else {
        toast.error('Oops. Something went wrong. Please try again later.');
      }
    }, 3000);
  };

  const displayContest = (e, contestId) => {
    e.preventDefault();
    setContestId(contestId);
    setShowContestModal(true);
  };

  const checkForExistingPollSubmission = () => {
    if (submittedPoll) {
      window.location.reload();
    } else {
      setShowPollsModal(false);
    }
  };

  const formatStartTime = (startDate) => {
    // {moment().local().format('ddd hh:mm a')}
    const startDateUtc = moment.utc(startDate);
    return startDateUtc.local().format('ddd hh:mm a');
  };

  const formatEventMonth = (startDate) => {
    // {moment().local().format('ddd hh:mm a')}
    const startDateUtc = moment.utc(startDate);
    return startDateUtc.local().format('MMM');
  };

  const formatEventDay = (startDate) => {
    // {moment().local().format('ddd hh:mm a')}
    const startDateUtc = moment.utc(startDate);
    return startDateUtc.local().format('DD');
  };

  return (
    <div className="utility-list">
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <Throbber throbberText="Loading media..." />
      </SimpleModal>
      <SimpleModal isOpen={showContestModal} onBeforeClose={() => setShowContestModal(false)}>
        <ContestEntry contestId={contestId} onCloseModal={() => setShowContestModal(false)} />
      </SimpleModal>
      <SimpleModal isOpen={showPollsModal} onBeforeClose={() => checkForExistingPollSubmission()}>
        <PollsEntry poll={currentPoll} onPollSubmission={() => setSubmittedPoll(true)} onClose={() => checkForExistingPollSubmission()} />
      </SimpleModal>
      {showFileViewer
        && (
        <div className="file-viewer">
          <div className={selectedVideo ? 'file-viewer__inner -video' : 'file-viewer__inner'}>
            <a className="modal__close -outside-right" href="#" onClick={(e) => closeFileViewer(e)}>
              <img src="https://assets.heyethos.com/global/icon-close-dark.svg" alt="Close modal" />
            </a>

            {selectedPdf
              && (
                <div className="pdf-viewer">
                  {isMobile() ? (
                    <div className="pdf-downloader">
                      <a className="button button-download" href={selectedPdf} download={selectedPdf} rel="noreferrer">Open Lookbook</a>
                    </div>
                  ) : (
                    <iframe src={selectedPdf + '#view=FitH&toolbar=0'} title="View PDF" allowFullScreen></iframe>
                  )}
                </div>
              )}

            {selectedFile
            && (
              // <Gallery creator={selectedFile} />
              <div className="file-viewer__scroller">
                <div className="file-viewer__gallery"><img src={selectedFile} alt="" /></div>
              </div>
            )}

            {selectedVideo
            && <AzureMediaPlayer src={selectedVideo} />}

            {/* <video data-setup='{"nativeControlsForTouch": false}' className="azuremediaplayer amp-default-skin" controls controlslist="nodownload" autoPlay="autoplay" loop muted>
              <source src={selectedVideo} type="video/mp4" />
              <track
                default
                kind="captions"
                srcLang="en"
                src="" />
              Your browser does not support the video tag.
            </video> */}

          </div>
        </div>
        )}
      {!noHeader
        && <h2 className="digital-asset__subheader">Benefits</h2>}

      {slideshow && formattedUtilities.length > 0
        && (
          <div className="utility-container -video-carousel">
            <Slider {...settings}>
              {formattedUtilities.map((utility) => (
                <div className="utility-item__slide" key={utility.utilityId}>
                  {getUtilityType(utility.utilityType) === 'Video Content'
                  && (
                    <div className="utility-item__slide--inner">
                      <div
                        className="utility-item__slide--image"
                        style={{
                          backgroundImage: 'url(' + utility.gatedContents[0].thumbnail + ')',
                        }}>
                        {/* <a href="#" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>View media</a> */}
                        {utility.gatedContents[0].contentType === 4
                          ? (
                            <a href="#" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>
                              <div className="icon-holder">
                                <img className="-download" src="https://assets.heyethos.com/ethos/ui/icon-download.svg" alt="Download Now" />
                              </div>
                            </a>
                          )
                          : (
                            <a href="#" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>
                              {utility.gatedContents[0].fileUrl.includes('.mp4')
                                ? (
                                  <div className="icon-holder">
                                    <img src="https://assets.heyethos.com/ethos/ui/icon-play2.svg" alt="Play Video" />
                                  </div>
                                )
                                : (
                                  <div className="icon-holder">
                                    <img className="-view" src="https://assets.heyethos.com/ethos/ui/icon-view.png" alt="Download Now" />
                                  </div>
                                )}
                            </a>
                          )}
                      </div>
                      <div className="utility-item__slide--title" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{utility.utilityName || utility.utilityName}</div>
                      <div className="utility-item__slide--description" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{truncateString(utility.gatedContents[0].description, 94)}</div>

                      {/* {utility.gatedContents[0].contentType === 2
                      && (
                      <div className="utility-item__cta">
                        <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>Watch Now</a>
                      </div>
                      )}
                      {utility.gatedContents[0].contentType === 4
                      && (
                      <div className="utility-item__cta">
                        <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>Download Now</a>
                      </div>
                      )} */}
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        )}
      {!slideshow
      && (
      <div className={layout === 'flex' ? 'utility-container -flex' : 'utility-container'}>
        { formattedUtilities.length > 0
          && formattedUtilities.map((utility) => (
            <div className="utility-slot" key={utility.utilityId}>
              {/* {getUtilityType(utility.utilityType)} */}
              {getUtilityType(utility.utilityType) === 'Coupon Voucher'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.thumbnailUrl + ')',
                    }}>
                  </div>
                  <div className="utility-item__verticle">
                    <div className="utility-item__title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description">{utility.utilityDescription}</div>
                    {utility.vouchers[0].voucherType === 5 ? (
                      <div>
                        {AccountManager.hasBirthdate() ? (
                          <div>
                            <CodeRevealer utility={utility} />
                          </div>
                        ) : (
                          <div className="utility-item__cta">
                            <a href={process.env.CUSTOM_BIRTHDATE_ENTRY_LINK || '/account/details'} className="button-collection" rel="noreferrer">Add my birth date</a>
                          </div>
                        )}
                      </div>
                    )
                      : (
                        <div>
                          <CodeRevealer utility={utility} onClicked={() => onPreviewCTA()} isPreviewMode={!!isPreviewMode} />
                        </div>
                      )}
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Polls'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.thumbnailUrl + ')',
                    }}>
                  </div>
                  <div className="utility-item__verticle">
                    <div className="utility-item__title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description" dangerouslySetInnerHTML={{ __html: utility.utilityDescription }}></div>
                  
                    <div className="utility-item__cta">
                      <a
                        href="#"
                        className="button-collection"
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => openPollModal(utility, e)}>
                        View poll
                      </a>
                    </div>

                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Generic Utility'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.genericPromotions[0].thumbnailUrl + ')',
                    }}>
                  </div>
                  <div className="utility-item__verticle">
                    <div className="utility-item__title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description" dangerouslySetInnerHTML={{ __html: utility.genericPromotions[0].description }}></div>
                  
                    {utility.genericPromotions[0].promoCode && (
                      <CodeRevealer utility={utility.genericPromotions[0]} />
                    )}
                    {utility.genericPromotions[0].linkUrl !== 'null' && !utility.genericPromotions[0].promoCode
                      && (
                      <div className="utility-item__cta">
                        {utility.genericPromotions[0].linkLabel
                        && <a href={utility.genericPromotions[0].linkUrl} className={utility.genericPromotions[0].linkUrl ? 'button-collection' : 'button-collection -disabled'} target="_blank" rel="noreferrer">{utility.genericPromotions[0].linkLabel}</a>}
                      </div>
                      )}
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Video Content'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    onClick={(e) => getGatedAsset(gate.gatedContentId, e)}
                    style={{
                      backgroundImage: 'url(' + utility.gatedContents[0].thumbnail + ')',
                    }}>
                  </div>
                  <div className="utility-item__title" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{utility.utilityName || utility.utilityDescription}</div>
                  <div className="utility-item__description">{utility.gatedContents[0].description}</div>
                  <div className="utility-item__cta">
                    {utility.gatedContents[0].contentType === 2
                    && <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>Watch now</a>}
                    {utility.gatedContents[0].contentType === 4
                    && <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>Download now</a>}
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Gated Commerce'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.gatedCommerces[0].productThumbnail + ')',
                    }}>
                  </div>
                  <div className="utility-item__verticle">
                    <div className="utility-item__title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description">
                      {utility.gatedCommerces[0].ecommerceProductDescription}
                    </div>
                  
                    <div className="utility-item__cta">
                      {utility.gatedCommerces[0].ecommerceProductUrl && utility.gatedCommerces[0].contentType === 10
                      && <a className="button-collection" href={utility.gatedCommerces[0].ecommerceProductUrl} target="_blank" rel="noreferrer">Get now</a>}
                      {utility.gatedCommerces[0].ecommerceProductUrl && utility.gatedCommerces[0].contentType !== 10
                      && <a className="button-collection" href={utility.gatedCommerces[0].ecommerceProductUrl} target="_blank" rel="noreferrer">Shop now</a>}
                    </div>
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Contests'
              && (
                <div className="utility-item">
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.contests[0].thumbnailImage + ')',
                    }}>
                  </div>
                  <div className="utility-item__verticle">
                    <div className="utility-item__title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description">{utility.contests[0].description}</div>
                 
                    <div className="utility-item__cta">
                      <>
                        <a onClick={(e) => displayContest(e, utility.contests[0].contestId)} className="button-collection" href="#" target="_blank" rel="noreferrer">Participate</a>
                      </>
                    </div>
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Events'
              && (
                <>
                  <div className="utility-item -events">
                    <div className="utility-item__event-cal">
                      <div className="utility-item__event-month">{formatEventMonth(utility.events[0].startDate)}</div>
                      <div className="utility-item__event-day">{formatEventDay(utility.events[0].startDate)}</div>
                    </div>
                    <div className="utility-item__event-title">{utility.utilityName || utility.utilityDescription}</div>
                    <div className="utility-item__description -event" dangerouslySetInnerHTML={{ __html: utility.events[0].description }}></div>
                    <div className="utility-item__description--location">
                      <span>{formatStartTime(utility.events[0].startDate)}</span>
                      {utility.events[0].address
                          && (
                          <span>
                            &nbsp;&bull;&nbsp;
                            {utility.events[0].address}
                          </span>
                          )}
                    </div>
                    <div className="utility-item__event-cta">
                      {utility.events[0].rsvpUrl ? (
                        <a href={utility.events[0].rsvpUrl} className="button-collection" target="_blank" rel="noreferrer">RSVP</a>
                      ) : (
                        <a className="button-collection -disabled" target="_blank" rel="noreferrer">RSVP</a>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
      )}
    </div>
  );
}

export default UtilityList;
