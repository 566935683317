import React, { useState, useEffect } from 'react';

const HowItWorks = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const copyStrings = {
    en: {
      heading: 'HOW IT WORKS',
      subHeading: 'Every $100 Spent = 1 point',
    },
  };

  const instructions = {
    en: [
      {
        id: 1,
        title: 'JOIN:',
        description: 'Sign up by entering your email.',
      },
      {
        id: 2,
        title: 'CHECK YOUR EMAIL:',
        description: 'Click through to validate your account and explore your rewards!',
      },
      {
        id: 3,
        title: 'START EARNING:',
        description: 'The more points you earn, the more benefits you receive!',
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          {copyStrings[lang].heading}
          <span>{copyStrings[lang].subHeading}</span>
        </div>
        <div className="how-it-works__list">
          {instructions[lang].map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">
                {step.title}
&nbsp;
                {step.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
