import React, { useState, useEffect } from 'react';

const lkaTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(true);

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>ALL MEMBERS</th>
              <th>1-12 POINTS</th>
              <th>13-36 POINTS</th>
              <th>37-100 POINTS</th>
              <th>101+</th>
            </tr>
            <tr>
              <td>Priority Enrollment</td>
              <td>Priority Enrollment</td>
              <td>Priority Enrollment</td>
              <td>Priority Enrollment</td>
              <td>Priority Enrollment</td>
            </tr>
            <tr>
              <td>Special Promotions</td>
              <td>Special Promotions</td>
              <td>Special Promotions</td>
              <td>Special Promotions</td>
              <td>Special Promotions</td>
            </tr>
            <tr>
              <td>Invitations to Special Events</td>
              <td>Invitations to Special Events</td>
              <td>Invitations to Special Events</td>
              <td>Invitations to Special Events</td>
              <td>Invitations to Special Events</td>
            </tr>
            <tr>
              <td>Access to Exclusive Contests</td>
              <td>Access to Exclusive Contests</td>
              <td>Access to Exclusive Contests</td>
              <td>Access to Exclusive Contests</td>
              <td>Access to Exclusive Contests</td>
            </tr>
            <tr>
              <td>Exclusive Access to Partner Discounts</td>
              <td>Exclusive Access to Partner Discounts</td>
              <td>Exclusive Access to Partner Discounts</td>
              <td>Exclusive Access to Partner Discounts</td>
              <td>Exclusive Access to Partner Discounts</td>
            </tr>
            <tr>
              <td></td>
              <td>(1) Bring a friend pass</td>
              <td>(2) Bring a friend passes</td>
              <td>(3) Bring a friend passes</td>
              <td>(4) Bring a friend passes</td>
            </tr>
            <tr>
              <td></td>
              <td>10% off Private Cooking Classes</td>
              <td>15% off Private Cooking Classes</td>
              <td>20% off Private Cooking Classes</td>
              <td>50% off Private Cooking Classes</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Progressive Knife Set Preferred Price 50% off (regular $249)</td>
              <td>Progressive Knife Set (value $249)</td>
              <td>Progressive Knife Set (value $249)</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>LKA Branded Gift</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default lkaTiers;
